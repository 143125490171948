@import '~@o4c/css/mixins/elevations';

.errors-container {
  padding: 24px 16px;
  display: flex;
  height: 100vh;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .o4c.card {
    @include elevation3();
    .header i {
      color: var(--negative);
    }
    .main p {
      margin-top: 0;
    }
  }
  .error-details {
    margin-top: 24px;
    padding: 16px;
    background: var(--grey-lighter);
    border-radius: var(--card-border-radius);
  }
}