@import '~@o4c/css/mixins/typography';

.OverviewShift {
  display: flex;
  align-items: center;
  > .header {
    align-items: center;
    // background: red;
    display: flex;
    width: 100%;
    .times {
      flex: 0 0 55px;
      span {
        display: block;
        &:first-child {
          font-weight: bold;
        }
      }
    }
    .details {
      flex: 1;
      position: relative;
      padding: 4px 0 4px 12px;
      &:before {
        content: "";
        display: block;
        width: 4px;
        border-radius: 2px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -3px;
        background: var(--shift-color);
      }
      span {
        display: block;
      }
      .meta {
        @include smallText();
        color: var(--grey-dark);
      }
    }
  } 
}