@import "~@o4c/css/components/cards";
@import "~@o4c/css/components/timelines";

.screen-header {
  .switch {
    flex-grow: 0;
    flex-basis: 20px;
  }
  > div {
    position: relative;
    z-index: 1;
  }
  .input-switch {
    margin-left: 8px;
  }
}

.calendar-container {
  flex-basis: 100%;
  border-top: 1px solid var(--grey-light);
  margin-top: -100%;
  transition: all 0.3s ease-in-out;
  z-index: 0 !important;
  &.show {
    margin-top: 0;
    opacity: 1;
  }
  &.hide {
    opacity: 0;
  }
}
